@import "../../theme.scss";

@media only screen and (max-width: 600px) {
    .pageContainer {
        width: 100% !important;     
    }

    .alignMiddle {
        width: 100% !important;
    }
}

body {
    background-image: radial-gradient(#dbf5fd 10%, $white100 16%);
    background-position: 0 0;
    background-size: 22px 22px;
}

.alignDots {
    position: absolute;
    right: 30px;
}

.pageContainer {
    margin-left: auto;
    margin-right: auto;
    display: table-cell;
    vertical-align: middle;
}

.alignMiddle {
    margin-left: auto;
    margin-right: auto;
    width: 458px;
    background-color: $white100;
    padding: 32px 16px 32px 16px;
    border-radius: 10px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
    position: relative;
}

.container {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.Close {
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    cursor: pointer;
}
.Heading {
    font-size: 20px;
    font-family: "Inter-Bold";
    margin-bottom: 16px;
}

.inputField {
    width: 100%;
    height: 3.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    border: 1px solid $black50;
    font-size: 14px;
    outline: none;
    padding-left: 10px;
    color: $black100;
    font-family: 'Inter';
    &::placeholder {
        color: $black50;
    }
    &:focus {
        border-color: $primaryBlue100;
    }
}

.inputs {
    float: center;
}

.error {
    border: 1px solid $infoFailed100 !important;
}

.label {
    color: $black50;
    font-size: 12px;
    font-family: 'Inter';
}

.labelRight {
    color: $black30;
    font-size: 14px;
    float: right;
}

.AlignButton {
    text-align: center;
    margin-top: 7rem;
}
.buttonNext {
    width: 100%;
    line-height: 1.5;
    padding: 10px;
    border-radius: 6px;
    outline: none;
    border: none;
    font-family: 'Inter-Light';
    text-align: center;
    &:focus {
        outline: none;
    }
}
.buttonNextEnable {
    background-color: $primaryBlue100;
    cursor: pointer;
    &:hover {
        background-color: $primaryBlue100;
    }
}
.buttonNextDisable {
    background-color: $black30;
    &:hover {
        cursor: not-allowed;
    }
}

.nextText {
    color: $white100;
    font-size: 16px;
}

.arrow {
    float: right;
    padding-right: 1rem;
    margin-top: 12px;
}

.warning {
    font-size: 14px;
    margin-top: 0.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $white100 inset !important;
}

.NavLinkfont {
    color: $black50;
    font-size: 12px;
    margin-top: 16px;
    // float: right;
    font-family: 'Inter-Light';
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.loginHeadBold {
    font-size: 16px;
    font-family: 'Inter-Bold';
    margin-bottom: 54px;
}

.alignDots {
    position: absolute;
    right: 30px;
}


.cardContainer {
    background-color: #f8f9fc;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    max-width: 741px;
    max-height: 380px;
    background-color: $white100;
    padding: 40px;
  }
  
  .footer {
    font-family: "Inter-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #828792;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .contactUs {
    color: $primaryBlue100;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  
  .successTick {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .successHead {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #050f24;
    margin-bottom: 60px;
  }
  
  .loginRedirectAlign {
    text-align: center;
    font-family: "Inter-Light";
    background-color: $primaryBlue100;
    color: $white100;
    border-radius: 8px;
    width: 100%;
    line-height: 1.5;
    padding: 10px 14px 14px 14px;
    margin-left: 40px;
    margin-right: 40px;
    max-width: 405px;
    &:hover {
      background-color: $primaryBlue100;
      cursor: pointer;
    }
  }
  
  .loginContainer {
    display: flex;
    justify-content: center;
  }

  .supportCol {
    font-family: Inter-Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #828792;
    text-align: right;
  }
  
  .SupportNavLinkfont {
    margin-left: 3px;
    text-decoration: none;
    font-size: 12px;
    font-family: "Inter";
    color: $primaryBlue100;
    &:hover{
        text-decoration: underline;
    }
    line-height: 0px;
    cursor: pointer;
  }