@import "../../theme.scss";

@media only screen and (max-width: 600px) {
    .pageContainer {
        width: 100% !important;     
    }

    .alignMiddle {
        width: 100% !important;
    }
}

body {
    background-image: radial-gradient(#dbf5fd 10%, #fff 16%);
    background-position: 0 0;
    background-size: 22px 22px;
}

.alignDots {
    position: absolute;
    right: 30px;
}

.pageContainer {
    margin-left: auto;
    margin-right: auto;
    display: table-cell;
    vertical-align: middle;
}

.alignMiddle {
    margin-left: auto;
    margin-right: auto;
    width: 458px;
    background-color: $white100;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
    position: relative;
}

.container {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.loginHeading {
    font-size: 20px;
    color: $black100;
    font-family: Inter-Bold;
    margin-top: 54px;
    margin-bottom: 24px;
    line-height: 22px;
    letter-spacing: -0.1px;
}

.loginHeadingOtp{
    font-size: 20px;
    color: $black100;
    font-family: Inter-Bold;
    margin-top: 54px;
    margin-bottom: 8px;
    line-height: 22px;
    letter-spacing: -0.1px;
}

.loginHeadBold {
    font-size: 16px;
    font-family: 'Inter-Bold'
}

.inputField {
    width: 100%;
    height: 56px;
    margin-top: 8px;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    border: 1px solid $primaryBlue5;
    font-size: 14px;
    outline: none;
    padding: 13px 16px 13px 16px;
    color: $black100;
    font-family: 'Inter';
    &::placeholder {
        color: $black30;
    }
    &:focus {
        border-color: $primaryBlue100;
    }
}
.error {
    border: 1px solid $infoFailed100 !important;
}
.inputs {
    width: -webkit-fill-available;
}

.mt8{
    margin-top: 8px;
}

.NavLinkfont {
    text-decoration: none;
    color:$black100;
    font-size: 12px;
    font-family: 'Inter';
    &:hover{
        text-decoration: underline;
        color:$primaryBlue100;
    }
    line-height: 0px;
}

.label {
    color: $black50;
    font-size: 12px;
    font-family: 'Inter';
}

.buttonNext {
    width: 100%;
    line-height: 1.5;
    padding: 10px 14px 14px 14px;
    border-radius: 6px;
    outline: none;
    border: none;
    font-family: 'Inter';
    text-align: center;
    &:focus {
        outline: none;
    }
    img{
        margin-left: 9px;
    }
}
.buttonNextEnable {
    background-color: $primaryBlue100;
    cursor: pointer;
}
.buttonNextDisable {
    background-color: #bbbbbb;
    &:hover {
        cursor: not-allowed;
    }
}

.buttonNextAnimation {
    animation: change_color 500ms forwards ;
    &:hover {
        cursor: not-allowed;
    }
}

@keyframes change_color {
    from {background-color: $primaryBlue100;}
    to {background-color: #bbbbbb;}
    
}

.nextText {
    color: $white100;
    font-size: 16px;
}

.arrow {
    float: right;
    padding-right: 1rem;
    margin-top: 12px;
}

.warning {
    font-size: 14px;
    color: $infoFailed100;
    margin-top: 8px;
}

.scrollbar::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #c2cbd3;
    border-radius: 4px;
}

.marginTop {
    margin-top: 20px;
}

.textPrimary {
    color: $black100;
    font-size: 14px;
    font-family: 'Inter-SemiBold';
}
.textSecondary {
    color: $black50;
    font-size: 14px;
    font-family: 'Inter';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}

.otpContainer {
    height: 3rem;
    width: 3rem !important;
    border: 1px solid #e0e0e0;
    margin-right: 16px;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Inter';
    margin-top: 1.5rem;
    color: $primaryBlue100;
    &:hover {
        border: 1px solid #e0e0e0 ;
    }
    &:focus{
        border: 1px solid $primaryBlue100 !important;
    }
}

.pointer {
    cursor: pointer;
}

.resendContainer {
    margin-top: 12px;
    display: inline-flex;
    width: 240px;
}

.resendText {
    font-size: 12px;
    font-family: 'Inter';
    color: $primaryBlue100;
    cursor: pointer;
}


.resendTextDisabled{
    filter: grayscale(0.7) brightness(1) hue-rotate(0deg);
    opacity: 0.4;
    font-size: 12px;
    font-family: 'Inter';
    pointer-events: none;
}

.linkTextMedium{
    text-decoration: none;
    color: $primaryBlue100;
    font-size: 14px;
    font-family: 'Inter-SemiBold';
    margin-top: 12px;
    background-color: $primaryBlue10;
    width: fit-content;
    padding: 6px 16px 6px 12px;
    border-radius: 8px;
    line-height: 22px;

    &:hover {
        color: $primaryBlue100;
        cursor: pointer;
    }
}

.loginHeadingSmall{
    font-size: 14px;
    color: $black100;
    font-family: Inter;
    line-height: 28px;
    letter-spacing: -0.1px;
}

.footer {
    margin-top: 24px;
    font-family: Inter-Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #828792;
    text-align: center;
  }
  
  .NavLinkfont {
    margin-left: 3px;
    text-decoration: none;
    font-size: 12px;
    font-family: "Inter";
    color: $primaryBlue100;
    &:hover {
      text-decoration: underline;
    }
    line-height: 0px;
  }
  .mfaError { 
    margin-bottom: 20px;
  }

  .mfaNotification{
    border: 0.5px solid #FFC053;
    padding: 1rem;
    background: #FFF3E8;
    color: #FF9518;
    font-size: 12px;
  }

  .footerCol{
      margin-top: 80px;
  }