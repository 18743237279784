@import "../../../../theme.scss";

.AddButton {
  border-radius: 20px;
  background-color: $active-link;
  color: $card-bg;
  font-size: 14px;
  padding: 10px 28px;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
    color: $card-bg;
    background-color: $add-hover;
  }
}
.Weight {
  font-size: 48px;
  font-family: "Gilroy-Light";
}
.BackgroundImage {
  background-color: $primary-background;
  height: 100vh;
}

.h1 {
  font-size: 24px;
}

.p {
  font-size: 14px;
}
