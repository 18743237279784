@import "../../theme.scss";

.Tab{
    color: $secondary-label;
    background-color:rgba(235, 236, 253, 0.205);
    border-radius: 4px;
}

.TabActive{
    background-color:$card-bg;
    color: $active-link;
    border-radius: 4px;
}

.TabsHover {
    padding: 10px 16px 10px 16px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 4px;
    font-size: 14px;
    &:hover{
        background-color:$secondary-background;
        color: $active-link;
        border-radius: 4px;
        cursor: pointer;
    } 
}

.cardScroll{
    max-height: 90vh;
    overflow: auto;
    font-size: 14px;
    padding-bottom: 2rem;
}

.Card{
    width: 100%;
    background-color:$card-bg ;
    padding: 18px;
    margin-top: 20px;
    // color: $secondary-label;
}
.userGroupCard{
    width: 100%;
    background-color:$card-bg ;
    padding: 18px;
    margin-top: 20px;
    &:hover{
        box-shadow:  0 2px 5px 0 rgba(0, 0, 0, 0.11), 0 2px 11px 0 rgba(0,0,0,0.11);
    }
}

.CardLabel{
    font-size: 24px;
    color: $active-link;
}

.clientCard{
    padding: 18px;
    background-color:$primary-background;
    margin-top: 10px;
    &:hover{
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11), 0 2px 11px 0 rgba(0,0,0,0.11);
    }
}

.revoke{
    color: $active-link;
    // padding: 10px;
    cursor: pointer;
    text-align: end;
    &:hover{
        color: red;
    }
    
}

.textMedium{
    font-size: 14px;
    color: $primary-label;
}

.textLarge{
    font-size: 22px;
    color: $primary-label;
}

.ModalPage{
    width: 72%;
    margin: 5% 10% 25% 10% ;
}

.ModalForm{
    max-height: 46rem;
    overflow-y: scroll;
    padding: 20px
}

.alignButton{
    margin-top: 2.5rem;
    margin-left: 1rem;
}

.userGroupStyle{
    cursor: pointer; 
}

.nonUserGroup{
    cursor: default;
}

.Tab{
    color: $secondary-label;
    background-color:rgba(235, 236, 253, 0.205);
    border-radius: 4px;
}

.TabActive{
    background-color:$card-bg;
    color: $active-link;
    border-radius: 4px;
}

.TabsHover {
    padding: 10px 16px 10px 16px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 4px;
    font-size: 14px;
    &:hover{
        background-color:$secondary-background;
        color: $active-link;
        border-radius: 4px;
        cursor: pointer;
    } 
}


.scrollbar::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #c2cbd3;
    border-radius: 4px;
}