@import '../../../theme.scss';

.logoMarginBottom{
    margin-bottom: 2rem;
    padding-top: 2rem;
    text-align: center;
      
}

.leftNav{
    background-color: $card-bg;
    box-shadow: 2px 0 4px 0 rgba(0,0,0,.06);
    z-index: 1;
    height: 100vh;
}
.size{
    height: 2.5rem;
    width: 2.5rem;
}

.NavLinkfont{
    font-size: 14px;
    color: $secondary-label;
    text-decoration: none;
    // padding: 8px;
    &:hover{
        cursor: pointer;
        color: $active-link;
        text-decoration: none;
        
    }
}

.NavLinkfontActive{
    font-size: 14px;
    color: $active-link;
    text-decoration: none;
    width: 10rem;
}

.leftNavAlign{
    text-align: center;
}