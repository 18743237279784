@import "../../../theme.scss";

.pageHead{
    font-size: 22px;
    color: $primary-label;
    margin-top: 1rem;
}

.form{
    padding : 30px;
    background-color: $card-bg;
}

.alignTabs{
    display: flex;
}

.label{
    font-size: 12px;
    font-family: 'Gilroy';
    font-weight: bold;
    color:$secondary-label;
    display: block;
    margin-bottom: 14px;
    min-height: 18px;
}

.KeyBg{
    background-color: $card-bg;
    padding: 12px;
    font-size: 14px;
}

.widthFix{
    margin-left: 3.25rem;
}

.save{
    // float: right;
    text-align: end;
    padding-bottom: 2rem;
}

.back{
    font-size: 16px;
    color: $secondary-label;
    cursor: pointer;
    transition: 1s;
    &:hover{
        text-decoration: none;
        color: $secondary-label;
        padding-left: 0.5rem;
        transition: 0.5s;
    }
}