@import'../../../theme.scss';

.Input {
    width: 100%;
    font-size: 14px;
    color: $secondary-label;
    box-sizing: border-box;
    align-self: flex-start;
}

.Label {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: bold;
    color:$primary-label;
    display: block;
    margin-bottom: 8px;
    min-height: 18px;
}

.LabelWithValue {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: bold;
    color:$secondary-label;
    display: block;
    margin-bottom: 8px;
    min-height: 18px;
}

.ErrorMessage{
    font: inherit;
    color: $danger;
    font-size: 12px;
}

.InputElement {
    outline: none;
    border: 1px solid $primary-background;
    background-color: $card-bg;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    height:40px;
    border-radius: 4px;
    &:focus{
        outline: none;
        border: 1px solid $primaryBlue100;
    }
    &::placeholder{
        outline: none;
        font: inherit;
        color:$secondary-label;
    }
}

.InputElementInvalid{
    outline: none;
    background-color:$card-bg;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    height:40px;
    border-radius: 4px;
    border: 1px solid $danger;
}

.requiredStar{
    color: $danger;
}

.inputField{
    outline: none;
    border: none;
    width: 100%;
    padding: 16px;
    font-size: 14px;
    box-sizing: border-box;
    align-self: flex-start;
    border-radius: 4px;
    font-family: "Inter";
    &::placeholder{
        outline: none;
        font: inherit;
        color:$black30;
        font-family: "Inter";
    }
}

.focusStyle{
    border: 1px solid $primary-background;
    border-radius: 4px;
    padding-right: 16px;
    display: flex;
    &:focus-within {
        border: 1px solid $primaryBlue100;
    }
}

.errorFocus{
    border: 1px solid $danger;
    border-radius: 4px;
    padding-right: 10px;
    display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:disabled,
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
}

input:disabled{
    cursor: not-allowed;
}