@import  '../../../theme.scss';
@import  '../../../assets/fonts/Gilroy-Light.ttf';

.backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
}


.Container {
    position : absolute;
    height : 283px;
    width : 432px;
    left : 25%;
    right : 25%;
    top : 25%;
    bottom : 25%;
    border-radius : 4px;
    background-color : $card-bg;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.11);
    margin : auto;
    padding : 25px;
    text-align : center;
}

.Icon40InfoContainer {
    margin-top : 10px;
    margin-bottom : 30px;
    margin : auto;    
}

.Warning {
    color: $secondary-label;
} 

.ButtonRectangle {
    display : inline-block;
    //margin-top: 25px;
}

.button {
    background-color: red;
    border: none;
    color: $card-bg;
    padding: 5px 5px;
    height : 40px;
    width : 148px;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    font-size: 16px;
    margin: 5px;
}
.AlertButton{
    background-color: $active-link;
    border: none;
    color: $card-bg;
    padding: 5px 5px;
    height : 40px;
    width : 148px;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    font-size: 16px;
    margin: 5px;
}
.buttonCancel {
    background-color:grey;
    border: none;
    color:whitesmoke;
    padding: 5px 5px;
    height : 40px;
    width : 148px;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    font-size: 16px;
    margin:5px;
}

.buttonOk {
    background-color: $add-hover;
    border: none;
    color:$card-bg;
    padding: 5px 5px;
    height : 40px;
    width : 148px;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    font-size: 16px;
    margin:5px;
}

.popUpText {
    font-family: 'Gilroy-Light';
}

