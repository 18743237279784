@import '../../theme.scss';
.clientCardAlign{
    cursor: pointer;
    padding: 10px;
}

.ResourceCard{
    border-radius: 5px;
    background-color: $card-bg;
    padding: 1rem;
    &:hover{
        box-shadow:  0 2px 5px 0 rgba(0, 0, 0, 0.11), 0 2px 11px 0 rgba(0,0,0,0.11);
    }
}
.main{
    padding : 22px ;
}

.cardTextSmall{
    font-size: 14px;
    color: $primary-label;
}
.cardTextMedium{
    font-size: 18px;
    color: $active-link;
}

.scroll{
    max-height: 85vh;
    overflow: auto;
}

.ModalPage{
    width: 70%;
    margin: 5% 10% 25% 15% ;
}

.add{
    padding: 8px 18px;
    font-size: 14px;
    color: $card-bg;
    background-color: $active-link;
    border-radius: 25px;
    margin-left: 920px;
    &:hover{
        color: $card-bg;
        text-decoration: none;
        background-color: $add-hover;
    }
}

.alignButton{
    float: right;
    text-align: end;
    padding-bottom: 2rem;
    padding-right: 20px;
}


.scrollbar::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #c2cbd3;
    border-radius: 4px;
}