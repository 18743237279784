@import '../../../theme.scss';

.NavLinkfont{
    padding: 8px 18px;
    font-size: 14px;
    color: $card-bg;
    background-color: $active-link;
    border-radius: 25px;
    text-align: center;
    &:hover{
        color: $card-bg;
        text-decoration: none;
        background-color: $add-hover;
    }
}