@import "../../../theme.scss";

.back {
    font-size: 16px;
    color: $secondary-label;
    cursor: pointer;
    transition: 1s;
    &:hover {
        text-decoration: none;
        color: $secondary-label;
        padding-left: 0.5rem;
        transition: 0.5s;
    }
}

.pageScroll {
    max-height: 100vh;
    overflow: auto;
}

.pageHead {
    font-size: 22px;
    color: $primary-label;
    margin-top: 1rem;
}

.Card {
    background-color: white;
    margin-top: 1rem;
}

.Heading {
    font-size: 24px;
    color: $active-link;
}

.CardScroll {
    // height: 45vh;
    // overflow-y: auto;
    // overflow-x: hidden;
    width: 78%;
    z-index: 1;
}

.group7 {
    font-family: "Gilroy";
    font-size: 18px;
    color: $primary-label;
    width: 34%;
}
.group8 {
    font-family: "Gilroy";
    font-size: 18px;
    color: $secondary-label;
    width: 34%;
}

.hoverPoint {
    &:hover {
        cursor: pointer;
    }
}

.optionsContainer {
    color: $secondary-label;
    font-family: "Gilroy";
    font-size: 12px;
    padding-right: 0px !important;
}

.OptionsPadding {
    padding-left: 15px;
}

.a {
    list-style-type: circle;
    padding-inline-start: 2vw;
}

.UnorderedList {
    list-style: none;
    padding: 0;
    margin: 0;
}
.List {
    width: 100%;
    text-align: left;
    padding-left: 5px;
    &:hover {
        background-color: $primary-background;
    }
}

.Container {
    display: block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.Checkmark {
    position: absolute;
    height: 13px;
    width: 13px;
    border-radius: 4px;
    margin-top: 0.5rem;
    border: 1px solid #8697a8;
    background-color: $card-bg;
}

.Container:hover input ~ .Checkmark {
    background-color: $primary-background;
}

.Container input:checked ~ .Checkmark {
    background-color: $active-link;
    border: none;
}

.Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.Container input:checked ~ .Checkmark:after {
    display: block;
}

.Container .Checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: 1px solid $card-bg;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.CheckBox {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 12px;
    width: 12px;
}

.toggleText {
    font-family: "Gilroy-Light";
    display: flex;
    margin-top: 0.8rem;
    // justify-content: flex-start;
}

.onCheck {
    font-family: "Gilroy";
    font-size: 14px;
    color: $secondary-label;
    width: 34%;
}

.hr {
    margin-bottom: 1.5rem;
    border-top: 2px solid $horizontal-line;
}

.Tab {
    color: $secondary-label;
    background-color: rgba(235, 236, 253, 0.205);
    border-radius: 4px;
}

.TabActive {
    color: $active-link;
    border-radius: 4px;
    background-color: $secondary-background;
}

.TabsHover {
    padding: 10px 16px 10px 16px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 4px;
    font-size: 14px;
    &:hover {
        background-color: $primary-background;
        color: $active-link;
        border-radius: 4px;
        cursor: pointer;
    }
}

.tokenBg {
    background-color: $primary-background;
    padding: 20px;
    border-radius: 20px;
    font-size: 14px;
    margin-top: 12px;
}

.tokenText {
    font-size: 15px;
    color: $active-link;
}

.tokenCard {
    // height: 5rem;
    max-height: 12rem;
    overflow: auto;
}

.nullWarn {
    color: $disabled-label;
    padding: 10px 20px;
    font-style: italic;
    font-size: 12px;
}

.actionButonAlign {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
}

.revokeButton {
    background-color: #fdebec;
    color: #ee3942;
    padding: 12px 20px;
    border-radius: 7px;
    font-size: 14px;
    &:hover {
        background-color: rgba(247, 218, 220, 0.897);
        cursor: pointer;
    }
}

.generateButton {
    background-color: $primary-background;
    color: $active-link;
    padding: 12px 20px;
    border-radius: 7px;
    font-size: 14px;
    margin-left: auto;
    margin-right: 1rem;
    margin-top: auto;
    &:hover {
        background-color: $secondary-background;
        cursor: pointer;
    }
}

.generateButtonDisabled {
    background-color: $disable-bg;
    color: $card-bg;
    padding: 10px 20px;
    border-radius: 7px;
    font-size: 14px;
    margin: auto 1rem auto auto;

    &:hover {
        // background-color: $secondary-background;
        cursor: not-allowed;
    }
}

.saveAlign {
    width: 13rem;
    padding-top: 1rem;
}

.tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    // z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    z-index: 9999;
    padding: 5px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.scrollbar::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #c2cbd3;
    border-radius: 4px;
}


.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    // border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: $primary-label;
    color: $card-bg;
    text-align: center;
    border-radius: 6px;
    padding: 2px 6px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: -20%;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
    // margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
    border-color: $primary-label transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
