@import '../../../theme.scss';

.option{
    // color: $active-link;
    padding : 7px 18px;
    background-color: $primary-background;
    border-radius: 25px;
    width: max-content;
    margin-bottom:0.5rem ;
    font-size: 14px;
    

}

.alignClose{
    margin-left: 2rem;
    cursor: pointer;
}

.alignSelf{
    margin-left: inherit;
    display: inline-flex;
}
.rightTick{
    margin-top: 2.7rem;
    cursor: pointer;
}

.multipleText{
    color: $active-link;
    font-size: 14px;
}