@import "../../../theme.scss";

.Backdrop {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.9);
  padding-bottom: 10rem;
}

.Container {
  
  border-radius : 4px;
  background-color : $card-bg;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.11);
  margin-left : auto;
  margin-right: auto;
  margin-top : 11rem;
  padding: 40px;
}

.Clock{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.SessionHeading{
  font-size: 40px;
  color: $card-link;
  text-align: center;
  margin-bottom: 0%;
  font-family: 'Gilroy-Medium';
}

.SessionSubheading{
  font-size: 24px;
  color: $primary-label;
  text-align: center;
  margin-bottom: 0%;
  font-family: 'Gilroy-Light';
}