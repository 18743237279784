@import "../../../theme.scss";

// .dropdownMenu{
//     color: $secondary-label;
//     border-radius: 25px;
//     margin-left: 0.5rem;
//     z-index: 9;
//     box-shadow: 0 2px 16px 0 rgba(0,0,0,0.11);
//     position: relative;
    
// }

// .dropdown{
//     background-color: $primary-background;
//     color: $active-link;
//     padding: 10px 20px 10px 20px;
//     border-radius: 25px;
//     margin-left: 0.5rem;
//     width: 10rem;
//     display: inline-block;
//     position: relative;
//     &:hover{
//         cursor: pointer;
//     }
// }

// .option{
//     padding: 10px 10px 10px 23px;
//     width: 9rem;
//     background-color: $card-bg;
//     border-radius: 25px;
//     &:hover{
//         background-color: $primary-background;
//         color: $primary-label;
//         cursor: pointer;
//         border-radius: 25px;
//     }
// }
// .tab{
//     padding: 8px 10px 6px 23px;
//     color: $active-link;
//     // width: 10rem;
//     &:hover{
//         cursor: pointer;
//     }
// }

// .label{
//     font-size: 12px;
//     font-family: 'Gilroy';
//     font-weight: bold;
//     color:$primary-label;
//     display: block;
//     margin-bottom: 10px;
//     min-height: 18px;
//     margin-left: 0.8rem;
//     margin-top: 1rem;
// }

// .position{
//     width: 10rem;
//     margin-left: 1rem;
//     // position: absolute;
// }

.SearchBy{
    // color: ;
    font-size: 12px;
    margin-bottom: 0.6rem;
    padding-left: 8px;
    // position: absolute;
    // right: 12rem;
}

.searchDropdown{
    background-color: $card-bg;
    
    border-radius: 20px;
    min-width: 14rem; 
    font-size: 14px;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.11);
    z-index: 20;
    position: absolute;
    
    // right: 0px;
}

.HorizontalLine{
    border: 0.5px solid $primary-background;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.Options{
    color:$secondary-label ;
    font-size: 14px;
    width: 100%;
    padding: 8px 20px;
    &:hover{
        background-color: $primary-background;
        color: $primary-label;
        cursor: pointer;
    }
}

.SearchOption{
    border-radius: 20px;
    background-color: $primary-background;
    padding:8px 20px;
    color: $active-link;
    min-width: 11rem;
    font-size: 14px;
    &:hover{
        cursor: pointer;
    }
}

.dropdownHead{
    color: $active-link;
}

.DropdownIcon{
    margin-top: 0.5rem;
}

.iconUp{
    margin-top: 0.5rem;
    padding-right: 16px;
    padding-top: 6px;
}