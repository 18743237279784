@import "../../../theme.scss";

.saveButton{
    border: none;
    outline: none;
    padding: 10px 32px 10px 32px;
    border-radius: 25px;
    background-color: $active-link;
    color: $card-bg;
    height: 40px;
    min-width: 80px;
    font-size: 14px;
    &:hover{
        background-color: $add-hover;
        cursor: pointer;
    }
}
.DisabledButton{
    border: none;
    outline: none;
    padding: 10px 32px 10px 32px;
    border-radius: 25px;
    background-color: $disable-bg;
    color: $card-bg;
    height: 40px;
    min-width: 80px;
    font-size: 14px;
    &:hover{
        cursor: not-allowed;
        color: $card-bg ;
    }
}

.cancelButton{
    outline: none;
    border: none;
    background-color: inherit;
    font-size: 14px;
    cursor: pointer;
    &:hover{
        color: grey;
    }
    &:focus{
        outline: none;
    }
}

.delete{
    outline: none;
    border: none;
    background-color: inherit;
    font-size: 14px;
    cursor: pointer;
    &:hover{
        color: red;
    }
}