@import '../../../theme.scss';

.navLink{
    padding: 8px 18px;
    font-size: 14px;
    color: $card-bg;
    background-color: $active-link;
    border-radius: 25px;
    float: right;
    &:hover{
        color: $card-bg;
        text-decoration: none;
        background-color: $add-hover;
    }
}



.NavLinkfont{
    font-size: 14px;
    color: $secondary-label;
    text-decoration: none;
    // padding: 8px;
    margin-left: 1rem;
    &:hover{
        color: $active-link;
        text-decoration: none;
        background-color:$primary-background ;
    }
}

.NavLinkfontActive{
    font-size: 14px;
    color: $active-link;
    text-decoration: none;
    padding: 8px;
    margin-left: 1rem;
    background-color:$card-bg ;
    width: 10rem;
}

.clientCardAlign{
    cursor: pointer;
    padding: 10px;
}

.clientCard{
    border-radius: 5px;
    background-color: $card-bg;
    padding: 1rem;
    &:hover{
        box-shadow:  0 2px 5px 0 rgba(0, 0, 0, 0.11), 0 2px 11px 0 rgba(0,0,0,0.11);
    }
}

.cardText{
    font-size: 22px;
    color: $active-link;
}

.cardTextSmall{
    font-size: 14px;
    color: $primary-label;
}
.content{
    padding: 22px;
}