@import '../../../theme.scss';

.Input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: $secondary-label;
    box-sizing: border-box;
    align-self: flex-start;
}

.Label {
    font-size: 12px;
    font-family: 'Gilroy';
    // font-weight: bold;
    color:$primary-label;
    display: block;
    margin-bottom: 8px;
    min-height: 18px;
}

.LabelWithValue {
    font-size: 12px;
    font-family: 'Gilroy';
    // font-weight: bold;
    color:$secondary-label;
    display: block;
    margin-bottom: 8px;
    min-height: 18px;
}

.InputElement {
    outline: none;
    border: 1px solid $primary-background;
    background-color: $card-bg;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    height:40px;
    border-radius: 4px;
    &:focus{
        outline: none;
        border: 1px solid $active-link;
    }
    &::placeholder{
        outline: none;
        font: inherit;
        color:$secondary-label;
    }
}

.InputElementInvalid{
    outline: none;
    background-color:$card-bg;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    height:40px;
    border-radius: 4px;
    border: 1px solid maroon;
}