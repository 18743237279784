@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
  src: url('./assets/fonts/Gilroy-Regular.eot');
  src: url('./assets/fonts/Gilroy-Regular.woff') format("woff"),
}

@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
  src: url('./assets/fonts/Gilroy-Bold.eot');
  src: url('./assets/fonts/Gilroy-Bold.woff') format("woff"),
  
}

@font-face {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  src: url('./assets/fonts/Gilroy-Medium.eot');
  src: url('./assets/fonts/Gilroy-Medium.woff') format("woff"),
  
}

@font-face {
  font-family: 'Gilroy-Light';
  font-style: normal;
  src: url('./assets/fonts/Gilroy-Light.ttf') format('truetype');
  src: url('./assets/fonts/Gilroy-Light.eot');
  src: url('./assets/fonts/Gilroy-Light.woff') format("woff"),
}

@font-face {
  font-family: 'Gilroy-Thin';
  font-style: normal;
  src: url('./assets/fonts/Gilroy-Thin.ttf') format('truetype');
  src: url('./assets/fonts/Gilroy-Thin.eot');
  src: url('./assets/fonts/Gilroy-Thin.woff') format("woff"),
  
}


@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Light";
  src: url("./assets/fonts/Inter/Inter-Light.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
}

code {
  font-family: 'Inter';
}


body::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

body::-webkit-scrollbar-thumb {
  background-color: #c2cbd3;
  border-radius: 4px;
}

