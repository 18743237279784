@import "../../theme.scss";

.Tab{
    color: $secondary-label;
    background-color:rgba(235, 236, 253, 0.205);
    border-radius: 4px;
}

.TabActive{
    background-color:$card-bg;
    color: $active-link;
    border-radius: 4px;
}

.TabsHover {
    padding: 10px 16px 10px 16px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 4px;
    font-size: 14px;
    &:hover{
        background-color:$secondary-background;
        color: $active-link;
        border-radius: 4px;
        cursor: pointer;
    } 
}

.cardScroll{
    max-height: 84vh;
    overflow: auto;
    font-size: 14px;
}

.Card{
    width: 100%;
    background-color:$card-bg ;
    padding: 22px;
    margin-top: 20px;
    // color: $secondary-label;
}

.CardLabel{
    font-size: 24px;
    color: $active-link;
}

.clientCard{
    padding: 18px;
    background-color:$primary-background;
    margin-top: 10px;
    &:hover{
        box-shadow:  0 2px 16px 0 rgba(0,0,0,0.11);
    }
}

.revoke{
    color: $active-link;
    // padding: 10px;
    float: right;
    cursor: pointer;
    &:hover{
        color: red;
    }
    
}

.textMedium{
    font-size: 14px;
    color: $primary-label;
}

.textLarge{
    font-size: 22px;
    color: $primary-label;
}


.scrollbar::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #c2cbd3;
    border-radius: 4px;
}