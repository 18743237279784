@import "../../../theme.scss";

@media only screen and (max-width: 600px) {
  .pageContainer {
    width: 100% !important;
  }

  .alignMiddle {
    width: 100% !important;
  }
}

.pageContainer {
  margin-left: auto;
  margin-right: auto;
  display: table-cell;
  vertical-align: middle;
}

.alignMiddle {
  margin-left: auto;
  margin-right: auto;
  width: 458px;
  background-color: $white100;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.backContainer {
  background-color: transparent !important;
}

.alignDots {
  position: absolute;
  right: 30px;
}

.contactUsIcon {
  margin-top: 90px;
  margin-bottom: 24px;
}

.heading {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 70px;
  margin-right: 70px;
  justify-content: center;
}

.link {
  color: $primaryBlue100;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.backBtn {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #696f7c;
  text-align: center;
  align-self: center;
  margin-top: 36px;
  margin-bottom: 90px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: $primaryBlue100;
  }
}
