@import "../../../theme.scss";

@media only screen and (max-width: 600px) {
  .pageContainer {
    width: 100% !important;
  }

  .alignMiddle {
    width: 100% !important;
  }
}

body {
  background-image: radial-gradient(#dbf5fd 10%, #fff 16%);
  background-position: 0 0;
  background-size: 22px 22px;
}

.alignDots {
  position: absolute;
  right: 30px;
}

.pageContainer {
  margin-left: auto;
  margin-right: auto;
  display: table-cell;
  vertical-align: middle;
}

.alignMiddle {
  margin-left: auto;
  margin-right: auto;
  width: 458px;
  background-color: $white100;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
  position: relative;
}

.container {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.Heading {
  font-size: 20px;
  color: $black100;
  font-family: Inter-Bold;
  margin-bottom: 8px;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.SubHeading {
  font-size: 14px;
  line-height: 22px;
  color: #262d33;
  font-family: Inter-Regular;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.contentTitle {
  @extend .SubHeading;
  margin-top: 24px;
  margin-bottom: 12px;
}
.contentContainer {
  display: flex;
  justify-content: space-evenly;
}
.content {
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 180px;
  background-color: #f8f9fc;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.11);
}
.disabledContent {
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
  }
}

.iconCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: #262d33;
  border-radius: 32px;
  height: 36px;
  width: 36px;
  background-color: #ffffff;
}
.itemTitle {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.itemDetails {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
}

.buttonNext {
  width: 100%;
  line-height: 1.5;
  padding: 10px 14px 14px 14px;
  border-radius: 6px;
  outline: none;
  border: none;
  font-family: "Inter";
  text-align: center;
  &:focus {
    outline: none;
  }
}
.buttonNextEnable {
  background-color: $primaryBlue100;
  cursor: pointer;
}

.buttonNextDisable {
  background-color: #bbbbbb;
  &:hover {
      cursor: not-allowed;
  }
}

.buttonNextAnimation {
  animation: change_color 500ms forwards ;
  &:hover {
      cursor: not-allowed;
  }
}

@keyframes change_color {
  from {background-color: $primaryBlue100;}
  to {background-color: #bbbbbb;}
}


.nextText {
  color: $white100;
  font-size: 16px;
  margin-right: 4px;
}

.marginTop {
  margin-top: 40px;
}

.footer {
  margin-top: 24px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #828792;
  text-align: center;
}

.NavLinkfont {
  margin-left: 3px;
  text-decoration: none;
  font-size: 12px;
  font-family: "Inter";
  color: $primaryBlue100;
  &:hover {
    text-decoration: underline;
  }
  line-height: 0px;
}

.selectedContent {
  position: relative;
}
.selectedContent::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 0;
  width: 0;
  background: transparent;
  border: 2px solid transparent;
  animation: animateBox1 250ms linear forwards;
}

.selectedContent::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 0;
  width: 0;
  background: transparent;
  border: 2px solid transparent;
  animation: animateBox2 250ms linear forwards;
  animation-delay: 250ms;
}

@keyframes animateBox1 {
  0% {
    height: 0;
    width: 0;
    border-top-color: #00ba88;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-left-radius: 8px;
  }
  50% {
    height: 0;
    width: 100%;
    border-top-color: #00ba88;
    border-right-color: #00ba88;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  100% {
    height: 100%;
    width: 100%;
    border-top-color: #00ba88;
    border-right-color: #00ba88;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@keyframes animateBox2 {
  0% {
    height: 0;
    width: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #00ba88;
    border-left-color: transparent;
    border-bottom-right-radius: 8px;
  }
  50% {
    height: 0;
    width: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #00ba88;
    border-left-color: #00ba88;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  100% {
    height: 100%;
    width: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #00ba88;
    border-left-color: #00ba88;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
}
.warning {
  font-size: 12px;
  white-space: pre-line;
  color: $infoFailed100;
  margin-top: 16px;
  display: flex;
  border: 1px solid #ffccc7;
  background: #fff1f0;
  border-radius: 2px;
  padding: 8px 16px;
  align-items: center;
  img{
    margin-right: 10px;
  }
}

