@import "../../theme.scss";

@media only screen and (max-width: 600px) {
    .pageContainer {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .scrollbar {
        width: 100% !important;
    }

    .alignMiddle {
        width: 100% !important;
    }

    .resetSuccessPageContainer {
        width: 100% !important;     
    }
}

.scrollbar {
    background-color: #ffffff;
    padding: 32px 16px 32px 16px;
    border-radius: 10px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
    width: 458px;
}

.pageContainer {
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.container {
    width: 100%;
    padding: 5rem 0rem;
    min-height: 100vh;
}

.Close {
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    cursor: pointer;
}
.Heading {
    font-size: 22px;
    font-family: Gilroy;
}

.inputField {
    width: 100%;
    height: 4rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    border: 1px solid $primary-background;
    font-size: 24px;
    outline: none;
    padding-left: 10px;
    color: $page-title;
    font-family: Gilroy-Light;
    &::placeholder {
        color: $secondary-label;
        font-family: Gilroy-Thin;
    }
    &:focus {
        border-color: $active-link;
    }
}

.inputs {
    margin-top: 16px;
}

.label {
    color: $secondary-label;
    font-size: 14px;
}

.labelRight {
    color: $active-link;
    font-size: 14px;
    float: right;
}

.hr {
    margin-top: 2rem;
    border-top: 2px solid $horizontal-line;
}

.AlignButton {
    text-align: center;
    margin-top: 2rem;
}
.buttonNext {
    width: 100%;
    line-height: 1.5;
    padding: 10px;
    border-radius: 6px;
    outline: none;
    border: none;
    font-family: Gilroy-Light;
    text-align: center;
    &:focus {
        outline: none;
    }
}
.buttonNextEnable {
    background-color: $active-link;
    cursor: pointer;
    &:hover {
        background-color: $add-hover;
    }
}
.buttonNextDisable {
    background-color: #bbbbbb;
    &:hover {
        cursor: not-allowed;
    }
}

.nextText {
    color: $card-bg;
    font-size: 24px;
}

.arrow {
    float: right;
    padding-right: 1rem;
    margin-top: 12px;
}

.infoText {
    font-size: 14px;
    margin-left: 0.5rem;
}

.errorText {
    font-size: 14px;
    color: $danger;
}

.errorContainer {
    position: absolute;
    margin-top: 0px;
}

.warning {
    border: 1px solid red !important;
}

.scrollbar::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #c2cbd3;
    border-radius: 4px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}

.NavLinkfont {
    color: $secondary-label;
    font-size: 12px;
    margin-top: 16px;
    // float: right;
    font-family: Gilroy-Light;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.loginHeadBold {
    font-size: 16px;
    font-family: Gilroy-Bold;
    margin-bottom: 54px;
}

.alignDots {
    position: absolute;
    right: 30px;
}

.resetHeadBold {
    font-size: 20px;
    line-height: 38px;
    font-family: 'Inter-Bold';
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: 18px;
}

.subHeading {
    color: #696F7C;
    font-family: 'Inter';
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}


.resetSuccessScrollbar::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.resetSuccessScrollbar::-webkit-scrollbar-thumb {
    background-color: #c2cbd3;
    border-radius: 4px;
}


.buttonLogin {
    background-color: $primaryBlue100;
    cursor: pointer;
    margin-top: 98px;
}

.loginButtonNext {
    width: 100%;
    line-height: 1.5;
    padding: 8px 14px 8px 14px;
    border-radius: 8px;
    outline: none;
    border: none;
    font-family: 'Inter';
    text-align: center;
}


.loginText {
    color: $white100;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

.resetPasswordSuccessContainer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.alignMiddle {
    margin-left: auto;
    margin-right: auto;
    width: 420px;
    background-color: $white100;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
    position: relative;
}

.resetSuccessPageContainer {
    margin-left: auto;
    margin-right: auto;
    display: table-cell;
    vertical-align: middle;
}

.contentContainer {
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}